<template>
    <div class="printer-page">
        <div class="title">
            打印机
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="openAddDialog(false)">添加打印机</el-button>
        </div>

        <div class="table-container">
            <el-table border :data="printerList">
                <el-table-column prop="name" align="center" label="名称">
                </el-table-column>
                <el-table-column prop="sn" align="center" label="编号">
                </el-table-column>
                <el-table-column prop="type" align="center" label="类型">
                </el-table-column>
                <el-table-column prop="pkey" align="center" label="KEY">
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                    <template slot-scope="scope">
                        {{ scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{ scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                     <template slot-scope="scope">
                        <span>
                         <el-popconfirm
                            confirmButtonText='好的'
                            cancelButtonText='不用了'
                            icon="el-icon-info"
                            iconColor="red"
                            @confirm="setPrinterStatus(scope.row.printer_id, scope.row.status)"
                            :title="`确定${ scope.row.status === '开启' ? '关闭' : '开启'}该打印机吗？`">
                            <el-button slot="reference" type="text" :style="`color: ${scope.row.status === '开启' ? '#f00' : ''}`" size="small">{{ scope.row.status === '开启' ? '关闭' : '开启' }}</el-button>
                        </el-popconfirm>
                     </span>
                     <span>
                        <el-button type="text" size="small" style="margin: 0 10px" @click="openAddDialog(true, scope.row)">编辑</el-button>
                    </span>
                    
                    <span>
                         <el-popconfirm
                            confirmButtonText='好的'
                            cancelButtonText='不用了'
                            icon="el-icon-info"
                            iconColor="red"
                            @confirm="deletePrinter(scope.row.printer_id)"
                            title="确定删除该打印机吗？">
                            <el-button slot="reference" type="text" size="small">删除</el-button>
                        </el-popconfirm>
                     </span>
                     </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total"
                           :page-size="size"
                           :current-page.sync="page" @current-change="getPrinterList">
            </el-pagination>
        </div>

        <el-dialog :title="`${isEdit ? '编辑' : '新增'}打印机`" class="add-dialog" :close-on-click-modal="false"
                   width="400px" :visible.sync="addPrinterDialog">
            <el-form :model="printerData" ref="addPrinterForm" label-width="90px">
                <el-form-item label="打印机名称" prop="name">
                    <el-input v-model="printerData.name" size="medium" placeholder="请输入打印机名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="打印机编号" prop="sn">
                    <el-input v-model="printerData.sn" size="medium" placeholder="请输入打印机编号"></el-input>
                </el-form-item>
                <el-form-item label="打印机类型" prop="type">
                    <el-select v-model="printerData.type" placeholder="请选择打印机类型">
                        <el-option v-for="item in printerTypeList" :label="item" :value="item" :key="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="打印机KEY" prop="pkey">
                    <el-input v-model="printerData.pkey" placeholder="请输入打印机KEY" size="medium" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addPrinterDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureSavePrinter('addPrinterForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: 'Printer',
    data () {
        return {
            printerTypeList: [],
            // 打印机列表
            printerList: [],
            page: 1,
            size: 10,
            total: 0,

            rules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'change' }
                ],
                sn: [
                    { required: true, message: '请输入编号', trigger: 'change' }
                ],
                type: [
                    { required: true, message: '请选择打印机类型', trigger: 'change' }
                ],
                pkey: [
                    { required: true, message: '请输入打印机KEY', trigger: 'change' }
                ]
            },
            // 新增/编辑数据
            printerData: {
                sn: '',
                pkey: '',
                type: '',
                name: ''
            },
            addPrinterDialog: false,
            isEdit: false
        }
    },
    mounted () {
        this.getPrintTypeList()
        this.getPrinterList()
    },
    methods: {
        // 获取打印机类型列表
        getPrintTypeList () {
            this.axios.post('/printer/get/type').then(res => {
                this.printerTypeList = { ...res.data }
            })
        },

        // 获取打印机列表
        getPrinterList () {
            this.axios.post('/printer/list', {
            }).then((res) => {
                this.printerList = res.data.list
                this.total = res.count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getPrinterList()
        },

        /**
         * 打开弹窗
         * @param isEdit 是否为编辑
         * @param printerData 为编辑时，打印机数据
         */
        openAddDialog (isEdit, printerData) {
            this.isEdit = isEdit
            this.addPrinterDialog = true
            // this.printerData.merchantsType = printerData.merchantsType
            if (isEdit) {
                this.printerData = {
                    printerId: printerData.printer_id,
                    sn: printerData.sn,
                    pkey: printerData.pkey,
                    type: printerData.type,
                    name: printerData.name
                }
            } else {
                this.printerData = {
                    sn: '',
                    pkey: '',
                    type: '',
                    name: ''
                }

                this.$nextTick(() => {
                    this.$refs.addPrinterForm.clearValidate()
                })
            }

            console.log(printerData)
        },

        /**
         * 删除打印机
         * @param printerId 删除ID
         */
        deletePrinter (printerId) {
            this.axios.post('/printer/delete', {
                printer_id: printerId
            }).then((res) => {
                if (res) {
                    this.getPrinterList()
                    this.$message.success('删除成功')
                }
            })
        },
        /**
         * 设置打印机状态
         * @param printerId 删除ID
         */
        setPrinterStatus (printerId, status) {
            this.axios.post('/printer/set/status', {
                printer_id: printerId,
                status: status === '开启' ? '关闭' : '开启'
            }).then((res) => {
                if (res) {
                    this.getPrinterList()
                    this.$message.success('设置成功')
                }
            })
        },

        /**
         * 保存打印机数据
         * @param formName 表单名称
         */
        sureSavePrinter (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.isEdit ? '/printer/update' : '/printer/add'
                    const { printerId } = { ...this.printerData }
                    this.axios.post(requestUrl, {
                        printer_id: printerId,
                        ...this.printerData
                    }).then((res) => {
                        console.log(res)
                        this.addPrinterDialog = false
                        this.$message.success(`成功${this.isEdit ? '编辑' : '添加'}打印机`)
                        this.getPrinterList()
                    }).catch(() => {
                        // console.log(error)
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
.printer-page {
    .title {
        padding: 14px;
        // margin-bottom: 18px;
        border-bottom: 1px solid #dddddd;
        font-size: 28px;
    }

    .search-div {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #dddddd;
    }

    .table-container {
        margin: 10px 20px 0;
    }

    .page-pageination {
        padding: 10px 0;
        text-align: right;
    }

    .add-dialog {
        .el-input, .el-select {
            width: 240px;
        }

        .select-time{
            // .el-input, .el-select {
            //     width: 140px;
            // }
            width: 140px;
        }

        .el-dialog__body {
            padding: 10px 20px;
        }
    }
}
</style>
